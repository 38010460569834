

<template>
    <div style="display: flex;">
      <svg width="38" height="34" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_71_93)">
          <path d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z" fill="black" fill-opacity="1"/>
          <path d="M21.5081 14.2943H22.4063C22.5249 14.295 22.6422 14.3188 22.7515 14.3643C22.8608 14.4098 22.9599 14.4762 23.0433 14.5596C23.1266 14.643 23.1926 14.7418 23.2373 14.8504C23.282 14.959 23.3046 15.0752 23.3039 15.1925V16.0901C23.3009 16.2086 23.322 16.3264 23.3658 16.4366C23.4096 16.5469 23.4752 16.6473 23.5589 16.7321C23.6426 16.8169 23.7426 16.8843 23.853 16.9303C23.9634 16.9763 24.082 17 24.2018 17C24.3216 17 24.4403 16.9763 24.5507 16.9303C24.6611 16.8843 24.7611 16.8169 24.8447 16.7321C24.9284 16.6473 24.9941 16.5469 25.0379 16.4366C25.0817 16.3264 25.1027 16.2086 25.0997 16.0901V15.1925C25.1017 14.8408 25.0336 14.4922 24.8992 14.1666C24.7649 13.841 24.567 13.5447 24.3168 13.2947C24.0667 13.0447 23.7691 12.8458 23.4412 12.7094C23.1133 12.5731 22.7615 12.5019 22.4057 12.5H21.5081C21.2673 12.5 21.0363 12.5946 20.866 12.7629C20.6957 12.9313 20.6 13.1596 20.6 13.3977C20.6 13.6357 20.6957 13.8641 20.866 14.0324C21.0363 14.2008 21.2673 14.2953 21.5081 14.2953V14.2943Z" fill="white"/>
          <path d="M15.1939 23.3049H16.092C16.3328 23.3049 16.5638 23.3994 16.7341 23.5678C16.9043 23.7361 17 23.9644 17 24.2024C17 24.4405 16.9043 24.6688 16.7341 24.8371C16.5638 25.0054 16.3328 25.1 16.092 25.1H15.1939C14.8382 25.0981 14.4863 25.0269 14.1584 24.8905C13.8305 24.7542 13.533 24.5553 13.2829 24.3052C13.0327 24.0552 12.8349 23.7589 12.7006 23.4332C12.5663 23.1076 12.4982 22.759 12.5003 22.4073V21.5097C12.4973 21.3913 12.5183 21.2736 12.5621 21.1633C12.6059 21.0531 12.6716 20.9526 12.7552 20.8678C12.8389 20.7831 12.9389 20.7157 13.0493 20.6697C13.1597 20.6237 13.2783 20.6 13.3981 20.6C13.5179 20.6 13.6365 20.6237 13.7469 20.6697C13.8573 20.7157 13.9573 20.7831 14.0409 20.8678C14.1246 20.9526 14.1902 21.0531 14.234 21.1633C14.2778 21.2736 14.2988 21.3913 14.2959 21.5097V22.4073C14.2952 22.5245 14.3179 22.6407 14.3627 22.7493C14.4075 22.8578 14.4735 22.9566 14.5569 23.04C14.6403 23.1233 14.7394 23.1896 14.8487 23.2351C14.9581 23.2805 15.0754 23.3042 15.1939 23.3049Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.596 18.0445C16.6749 18.2888 16.8599 18.4968 17.112 18.6248C17.3641 18.7527 17.6636 18.7905 17.9472 18.7302C18.2306 18.6662 18.4748 18.5099 18.6291 18.2936C18.7833 18.0774 18.8358 17.8178 18.7757 17.5688L16.7928 10.6102C16.6571 10.1232 16.3372 9.68994 15.8842 9.37981C15.4313 9.06969 14.8716 8.90072 14.2949 8.89999C13.7201 8.90065 13.1624 9.07015 12.7124 9.38095C12.2624 9.69176 11.9466 10.1256 11.8163 10.612L9.8341 17.5688C9.76099 17.8226 9.80643 18.0913 9.96049 18.316C10.1146 18.5408 10.3647 18.7035 10.6564 18.7686C10.7999 18.8013 10.9496 18.8086 11.0965 18.7899C11.2433 18.7712 11.3844 18.7271 11.5111 18.66C11.6385 18.5922 11.7491 18.503 11.8364 18.3977C11.9236 18.2923 11.9858 18.1728 12.0193 18.0463L12.6503 15.8268H15.9643L16.596 18.0445ZM14.0049 11.0889L13.2165 13.8484H15.4023L14.6139 11.0889C14.5909 11.0346 14.5495 10.9876 14.4951 10.9541C14.4408 10.9205 14.376 10.9021 14.3094 10.9012C14.243 10.9031 14.1788 10.9219 14.1246 10.9553C14.0705 10.9887 14.0289 11.0351 14.0049 11.0889Z" fill="white"/>
          <path d="M23.6493 19.7C24.3567 19.7 24.9612 19.8007 25.4627 20.0021C25.9731 20.1929 26.394 20.4738 26.7254 20.8449C26.9761 21.1099 27.1776 21.412 27.3299 21.7512C27.4911 22.0905 27.6075 22.4827 27.6791 22.9279C27.7597 23.3731 27.8 23.8873 27.8 24.4703V27.8C27.8 28.2971 27.3971 28.7 26.9 28.7H20.6C20.103 28.7 19.7 28.2971 19.7 27.8V24.4385C19.7 23.8873 19.7358 23.3943 19.8075 22.9597C19.8881 22.5145 20.0045 22.1223 20.1567 21.783C20.3179 21.4332 20.5149 21.1258 20.7478 20.8608C21.0791 20.4897 21.4776 20.2035 21.9433 20.0021C22.4179 19.8007 22.9866 19.7 23.6493 19.7ZM23.0179 20.829C22.6418 20.829 22.3105 20.9403 22.0239 21.1629C21.7373 21.3855 21.5045 21.6823 21.3254 22.0534C21.1821 22.3502 21.0657 22.7159 20.9761 23.1505C20.8955 23.5746 20.8552 24.1046 20.8552 24.7406V27.57C20.8552 27.5969 20.877 27.6187 20.9039 27.6187C20.9158 27.6187 20.9273 27.6143 20.9362 27.6064C21.5396 27.0695 22.0767 26.5065 22.5478 25.9173C23.0224 25.3237 23.394 24.7194 23.6627 24.1046C23.9403 23.4897 24.0791 22.8908 24.0791 22.3078C24.0791 22.011 24.0388 21.7565 23.9582 21.5445C23.8866 21.3219 23.7746 21.147 23.6224 21.0198C23.4702 20.8926 23.2687 20.829 23.0179 20.829ZM25.2343 22.4509C25.2343 23.0763 25.1134 23.6912 24.8717 24.2954C24.6299 24.8996 24.2851 25.5092 23.8373 26.124C23.4267 26.6782 22.9445 27.2457 22.3907 27.8266C22.3317 27.8886 22.2276 27.8515 22.2204 27.7662C22.2157 27.7111 22.2567 27.6628 22.3117 27.6585C22.5371 27.6412 22.7591 27.628 22.9776 27.6187C23.2284 27.5975 23.4791 27.5869 23.7299 27.5869H25.7448C26.2418 27.5869 26.6448 27.184 26.6448 26.6869V24.7406C26.6448 23.9562 26.5776 23.3042 26.4433 22.7848C26.3179 22.2654 26.1075 21.8519 25.812 21.5445C25.5232 21.2338 25.1447 21.004 24.6765 20.8552C24.6579 20.8493 24.6469 20.8301 24.6514 20.8111C24.6588 20.7805 24.6985 20.7727 24.7164 20.7986C24.8648 21.0129 24.9837 21.2404 25.0731 21.4809C25.1806 21.7353 25.2343 22.0587 25.2343 22.4509Z" fill="white" stroke="white" stroke-width="0.54"/>
        </g>
        <defs>
          <clipPath id="clip0_71_93">
          <rect width="37" height="37" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </template>
  
  <script>

  export default {
    name: "LangSwitch",
  };
  </script>
  <style lang="scss">
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";
    .swichColor {
      fill: $clr-lang-fill;
    }
    .swichColorStroke {
      stroke: $clr-lang-stroke;
    }
    .fillblackwhite{
      fill:$clr-saina-topnav-btn
    }
    .fillstrokecr{
      stroke: $clr-saina-topnav-btn;
    }
  </style>

<script>

export default {
  name: "LangSwitch",
};
</script>
<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  .swichColor {
    fill: $clr-lang-fill;
  }
  .swichColorStroke {
    stroke: $clr-lang-stroke;
  }
</style> 